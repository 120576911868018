<template>
    <div class="master-toko">
        <header-page>
            <span class="size14 fw-bold-700" style="color: #0B5FFF;margin-right: 5px;">Master</span>
            <span class="text-dark text-darken-5" style="margin-right: 5px;">></span>
            <span class="text-dark fw-bold-400 size14">Billing</span>
        </header-page>

        <TableBilling :get-list-subscription="getListSubscription" :result="result" @filter="getFilter" />
    </div>
</template>


<script>
import _ from 'lodash'
import { BContainer } from 'bootstrap-vue'
import TableBilling from '@/components/Billing/TableBilling.vue'

import HeaderPage from '@/components/HeaderPage.vue'

export default {
    title() {
        return 'Master Billing'
    },
    components: {
        BContainer,
        HeaderPage,
        TableBilling,
    },
    data() {
        return {
            currentPage: 1,
            result: {},
            filter: {
                search: '',
                per_page: 10,
                sort_type: 'asc',
            },
        }
    },
    watch: {
        filter: {
            handler: _.debounce(function () {
                this.getListSubscription()
            }, 300),
            deep: true,
        },
    },
    created() {
        this.getListSubscription()
    },
    methods: {
        getFilter(value) {
            this.filter = value
        },
        async getListSubscription(page = 1) {
            const queryParams = this.filter
            queryParams.page = page
            this.$store.dispatch('subscribe/getListSubscription', { params: queryParams })
                .then(result => {
                    this.result = result.data
                    this.currentPage = result.data.data.current_page
                }).catch(err => {
                    console.log(err)
                })
        },
    }
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';

.master-toko {
    button.bg-white {
        &:focus {
            background-color: transparent !important;
        }
    }
}
</style>
